import { Controller } from "stimulus"
import autocomplete from "autocomplete.js"
import axios from "axios"

export default class extends Controller {
  static targets = [ "field" ]

  static classes = [  ]

  static values = {
    url: String
  }

  connect() {
    this.ac = autocomplete(this.fieldTarget, { hint: false }, [
      {
        source: this.source(),
        debounce: 200,
        templates: {
          suggestion: function (suggestion) {
            return suggestion.name;
          },
        },
      },
    ]).on("autocomplete:selected", (event, suggestion, dataset, context) => {
      this.ac.autocomplete.setVal(suggestion.name)
    })
  }

  source() {
    const url = this.urlValue
    return (query, callback) => {
      axios.get(url, {headers: {'Accept': 'application/json'}, params: {query}}).then((response) => {
        callback(response.data)
      })
    }
  }
}
