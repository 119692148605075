import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "fieldSet" ]
  static classes = [ "hidden" ]

  connect() {

  }

  toggle(event) {
    console.log(event.target.value)
    if (event.target.value == 0) {
      this._show()
    } else {
      this._hide()
    }
  }

  _hide() {
    this.fieldSetTarget.classList.add(this.hiddenClass)
  }

  _show() {
    this.fieldSetTarget.classList.remove(this.hiddenClass)
  }
}
