import { Controller } from "stimulus";
import Chart from 'chart.js/auto';

export default class extends Controller {
  static values = {
    smartBank: Array,
    traditionalBank: Array,
    cashflow: Number,
    debt: Number,
    spending: Number
  }

  static targets = ["line", "doughnut"]

 lineTargetConnected(element) {
    this.buildLineChart()
  }

  doughnutTargetConnected(element) {
    this.buildDoughnutChart()
  }

  buildLineChart() {
    const canvas = document.getElementById("payoff-chart");
    const container = canvas.parentElement;

    canvas.width = container.clientWidth;
    canvas.height = container.clientHeight;
    const ctx = canvas.getContext("2d");
    const lastYearSmartBanking = this.smartBankValue.at(-1)[0] // point for last year of smart banking

    const data = {
      labels: [],
      datasets: [
        {
          label: "101 SMART Banking",
          backgroundColor: "#492286",
          data: this.smartBankValue,
          borderWidth: 0,
          borderRadius: 6,
          fill: true,
          showLine: true,
          pointBorderWidth: 2,                       // Make point border prominent
          pointBorderColor: '#492286', // Point border color
          pointHoverBorderWidth: 5,                  // Hover effect for the point
          pointHoverRadius: 7,                       // Larger point on hover
          pointRadius: function(context) {
            // Get the current index and value
            const index = context.dataIndex;
            const value = context.dataset.data[index];
            if (index == 0 || index == context.dataset.data.length - 1) {
              return 5
            } else {
              return 0
            }
          },
        },
        {
          label: "Conventional Banking",
          backgroundColor: "#e2e2ee",
          data: this.traditionalBankValue,
          borderWidth: 0,
          borderRadius: 6,
          fill: true,
          ticks: false,
          showLine: true,
          pointBorderWidth: 2,                       // Make point border prominent
          pointBorderColor: '#e2e2ee', // Point border color
          pointHoverBorderWidth: 5,                  // Hover effect for the point
          pointHoverRadius: 7,                       // Larger point on hover
          pointRadius: function(context) {
            // Get the current index and value
            const index = context.dataIndex;
            if (index == 0 || index == context.dataset.data.length - 1 ) {
              return 5
            } else {
              return 0
            }
          },
        }
      ]
    };

    new Chart(ctx, {
      type: 'line',
      data: data,
      options: {
        plugins: {
          legend: {
            position: 'top',
            align: 'end',
            labels: {
              usePointStyle: true,
            }
          },
        },
        scales: {
          y: {
            ticks: {
              // Add a $ sign before the label value
              callback: function(value) {
                return '$' + value.toLocaleString();
              }
            },
          },
          x: {
            ticks: {
              callback: function(value, index, ticks) {
                const label = this.getLabelForValue(value);
                const beginningYear = data.labels[0];
                const endYear = data.labels[data.labels.length - 1];

                // Show only the beginning year, end year of traditional banking, and last year of smart banking
                if (label === beginningYear || label === endYear || label === lastYearSmartBanking) {
                  return label;
                }
                return ''; // Hide other labels
            }
            }
          },
        }
      }
    });
  }

  buildDoughnutChart() {
    const canvas = document.getElementById("cashflow-chart");
    const ctx = canvas.getContext("2d");

    const container = canvas.parentElement;
    canvas.width = container.clientWidth;
    canvas.height = container.clientHeight;

    const data = {
      labels: [],
      datasets: [
        {
          data: [this.cashflowValue, this.debtValue, this.spendingValue],
          backgroundColor: [
            'rgb(137, 169, 129)', // green - cashflow
            'rgb(167, 33, 22)', // red - debt
            'rgb(205, 140, 48)' // orange - spending
          ]
        }
      ]
    }

    new Chart(ctx, {
      type: 'doughnut',
      data: data,
      options: {
        datasets: {
          doughnut: {
            rotation: 130,
            borderWidth: 7,
            borderColor: 'white',
            hoverBorderWidth: 2
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function(tooltipItem) {
                // Customize the tooltip label to show only the value, without color box
                return `$${tooltipItem.raw}`;
              }
            },
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            bodyColor: 'rgba(0,0,0)',
            displayColors: true,  // Removes the color box from the tooltip
            boxPadding: 5,
            borderColor: 'rgba(0,0,0,1)',
            borderWidth: 1,
          }
        }
      }
    })
  }
}
