import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "instructorId", "isSet", "searchField" ]
  static classes = [ "hidden" ]

  connect() {

  }

  setInstructorId(event) {
    const id = event._args[0].id
    this.instructorIdTarget.value = id
    this.isSetTarget.classList.remove(this.hiddenClass)
    console.log(id)
  }

  toggleSearchField(event) {
    if (event.target.value == 'true') {
      this._enableSearchField()
    } else {
      this._disableSearchField()
    }
  }

  _disableSearchField() {
    this.searchFieldTarget.classList.add(this.hiddenClass)
    this.searchFieldTarget.disabled = true
  }

  _enableSearchField() {
    this.searchFieldTarget.classList.remove(this.hiddenClass)
    this.searchFieldTarget.disabled = false
    this.searchFieldTarget.focus()
  }
}
